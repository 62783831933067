<template>
  <div id="servicesView">
    <SecondaryHero
      v-if="secondaryHeroData"
      :secondary-hero-data="secondaryHeroData"
    />
    <HighlightsContainer :highlights-data="highlightsData" />
  </div>
</template>

<script>
import api from '@/api'
import { mapMutations, mapState } from 'vuex'
import helpers from '@/api/helpers/helpers'
import SecondaryHero from '@/components/Hero/SecondaryHero'
import HighlightsContainer from '@/components/Highlights/HighlightsContainer'

export default {
  name: 'Services',
  components: { HighlightsContainer, SecondaryHero },
  data() {
    return {
      secondaryHeroData: null,
      highlightsData: [],
    }
  },
  computed: {
    ...mapState({
      pageLoading: (state) => state.pageLoading,
    }),
  },
  mounted() {
    setTimeout(() => {
      this.setPageLoadingFalse()
    }, 300)
    this.loadServicesData()
  },
  methods: {
    ...mapMutations({
      setPageLoadingFalse: 'setPageLoadingFalse',
    }),
    loadServicesData() {
      this.setServicesData(helpers.readStoredResponse('view.services'))

      api.views.loadServices().then((response) => {
        this.setServicesData(response)
      })
    },
    setServicesData(response) {
      if (!response || !response.entry) return

      this.secondaryHeroData =
        response.entry.secondaryHero && response.entry.secondaryHero.length
          ? response.entry.secondaryHero[0]
          : null

      this.highlightsData = response.entry.highlights || []
    },
  },
}
</script>
